import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import "./assets/style/custom/index.scss";
import "./assets/style/custom/custom.scss";
import "./assets/style/custom/vue_slick_custom.scss";
import i18n from "./i18n";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

Vue.use(ElementUI);
Vue.component("VueSlickCarousel", VueSlickCarousel);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
