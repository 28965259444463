<template>
  <div class="menu" :class="{ backchange2: isAbout }">
    <div class="menu-container">
      <div class="menu-logo">
        <router-link
          :to="{ name: 'langding' }"
          class="navbar-brand"
          v-if="isAbout"
        >
          <img
              v-if="!isChangeLogo"
              src="../assets/images/Logo_website.png"
              alt="Logo"
              class="style-logo style-logo--scroll"
          />
          <img
            src="../assets/images/Logo_website_dark.png"
            alt="Logo"
            class="style-logo style-logo--scroll"
          />
        </router-link>
        <a class="navbar-brand" href="#" v-else>
          <img
            v-if="!isChangeLogo"
            src="../assets/images/Logo_website.png"
            alt="Logo"
            class="style-logo style-logo--scroll"
          />
          <img
              v-else
              src="../assets/images/Logo_website_dark.png"
              alt="Logo"
              class="style-logo style-logo--scroll"
          />
        </a>
      </div>
      <div class="menu-mobile">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
      <ul class="sack-item">
        <div class="icon-close">
          <img src="../assets/images/x.svg" alt="" />
        </div>
        <li class="menu-item" v-if="!isCheckClick">
          <router-link :to="{ name: 'langding' }" class="menu-link"
            >Home <i class="sack-menu-effect"></i
          ></router-link>
        </li>
        <li class="menu-item li-item" v-if="isCheckClick">
          <a
            class="menu-link"
            href="#whyblockchain"
            @click="changeActive(0)"
            :class="tabActive === 0 ? 'active' : null"
            >About us
            <i class="sack-menu-effect"></i>
          </a>
        </li>
<!--        <li class="menu-item li-item" v-if="isCheckClick">-->
<!--          <a-->
<!--              class="menu-link"-->
<!--              href="#services"-->
<!--              @click="changeActive(1)"-->
<!--              :class="tabActive === 1 ? 'active' : null"-->
<!--          >Services-->
<!--            <i class="sack-menu-effect"></i>-->
<!--          </a>-->
<!--        </li>-->

        <el-dropdown class="menu-item li-item" v-if="isDesktop">
          <span class="el-dropdown-link menu-link">
            <a
                class="menu-link"
                href="#services"
                @click="changeActive(1)"
                :class="tabActive === 1 ? 'active' : null"
            >Services
            <i class="sack-menu-effect"></i>
              <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
          </a>
          </span>
          <el-dropdown-menu slot="dropdown" class="dropdown-service">
            <el-dropdown-item><a href="#product-growth">Product & Growth Advisory</a></el-dropdown-item>
            <el-dropdown-item><a href="#whitepaper">Whitepaper Development</a></el-dropdown-item>
            <el-dropdown-item><a href="#saas">SaaS Marketing</a></el-dropdown-item>
            <el-dropdown-item><a href="#cex-listing">Centralized Exchange Listing</a></el-dropdown-item>
            <el-dropdown-item><a href="#influence-marketing">Influencer Marketing</a></el-dropdown-item>
            <el-dropdown-item><a href="#press-release">Press Release</a></el-dropdown-item>
            <el-dropdown-item><a href="#search-engine">Search Engine Optimization</a></el-dropdown-item>
            <el-dropdown-item><a href="#social">Social Media Marketing</a></el-dropdown-item>
            <el-dropdown-item><a href="#community">Community Management</a></el-dropdown-item>
            <el-dropdown-item><a href="#outdoor">Outdoor Advertising</a></el-dropdown-item>
            <el-dropdown-item><a href="#trending">Trending</a></el-dropdown-item>
            <el-dropdown-item><a href="#market">Market-Making</a></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-collapse v-else class="menu-item li-item" accordion>
          <el-collapse-item class="collapse-item" name="1">
            <div slot="title" class="w-100">
              <span class="el-dropdown-link menu-link">
                <a
                    class="menu-link"
                    href="#services"
                    @click="changeActive(1)"
                    :class="tabActive === 1 ? 'active' : null"
                >Services
                <i class="sack-menu-effect"></i>
                </a>
              </span>
            </div>

            <p class="title">
              <a href="#product-growth" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Product & Growth Advisory</a>
            </p>
            <p class="title">
              <a href="#whitepaper" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Whitepaper Development</a>
            </p>
            <p class="title">
              <a href="#saas" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">SaaS Marketing</a>
            </p>
            <p class="title">
              <a href="#cex-listing" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Centralized Exchange Listing</a>
            </p>
            <p class="title">
              <a href="#influence-marketing" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Influencer Marketing</a>
            </p>
            <p class="title">
              <a href="#press-release" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Press Release</a>
            </p>
            <p class="title">
              <a href="#search-engine" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Search Engine Optimization</a>
            </p>
            <p class="title">
              <a href="#social" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Social Media Marketing</a>
            </p>
            <p class="title">
              <a href="#community" @click="changeActive(1)"
                 :class="tabActive === 1 ? 'active' : null">Community Management</a>
            </p>
            <p class="title"><a href="#outdoor" @click="changeActive(1)"
                                :class="tabActive === 1 ? 'active' : null">Outdoor Advertising</a></p>
            <p class="title"><a href="#trending" @click="changeActive(1)"
                                :class="tabActive === 1 ? 'active' : null">Trending</a></p>
            <p class="title"><a href="#market" @click="changeActive(1)"
                                :class="tabActive === 1 ? 'active' : null">Market-Making</a></p>
          </el-collapse-item>
        </el-collapse>

<!--        <li class="menu-item li-item" v-if="isCheckClick">-->
<!--          <a-->
<!--            class="menu-link"-->
<!--            href="#outsourcing"-->
<!--            @click="changeActive(2)"-->
<!--            :class="tabActive === 2 ? 'active' : null"-->
<!--            >Outsourcing-->
<!--            <i class="sack-menu-effect"></i>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="menu-item li-item" v-if="isCheckClick">-->
<!--          <a-->
<!--            class="menu-link"-->
<!--            href="#product"-->
<!--            @click="changeActive(3)"-->
<!--            :class="tabActive === 3 ? 'active' : null"-->
<!--            >Products-->
<!--            <i class="sack-menu-effect"></i>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="menu-item li-item" v-if="isCheckClick">-->
<!--          <a-->
<!--            class="menu-link"-->
<!--            href="#customers"-->
<!--            @click="changeActive(4)"-->
<!--            :class="tabActive === 4 ? 'active' : null"-->
<!--            >Customers-->
<!--            <i class="sack-menu-effect"></i>-->
<!--          </a>-->
<!--        </li>-->
        <li class="menu-item li-item" v-if="isCheckClick">
          <a
              class="menu-link"
              href="#partners"
              @click="changeActive(5)"
              :class="tabActive === 5 ? 'active' : null"
          >Partners
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item">
          <a
            class="menu-link"
            href="#teammate"
            @click="changeActive(6)"
            :class="tabActive === 6 ? 'active' : null"
            >Team
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item li-item">
          <a
            class="menu-link"
            href="#contact"
            @click="changeActive(7)"
            :class="tabActive === 7 ? 'active' : null"
            >Contact
            <i class="sack-menu-effect"></i>
          </a>
        </li>
        <li class="menu-item network">
          <div class="social_list">
            <div class="social_iem">
              <a href="https://www.facebook.com/globalvirality" target="_blank">
                <div>
                  <img src="../assets/images/facebook.svg" alt="" />
                </div>
              </a>
            </div>
            <div class="social_iem">
              <a href="https://www.linkedin.com/company/global-virality/" target="_blank">
                <div>
                  <img src="../assets/images/linkin.svg" alt="" />
                </div>
              </a>
            </div>
            <!--            <div class="social_iem">-->
            <!--              <div>-->
            <!--                <img src="../assets/images/youtube.svg" alt="" />-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="social_iem">
              <a href="https://twitter.com/Global_Virality" target="_blank">
                <div>
                  <img src="../assets/images/twitter.svg" alt="" />
                </div>
              </a>
            </div>
          </div>
        </li>
<!--        <li class="menu-item lang1">-->
<!--          <div class="language-select-wraper">-->
<!--            <el-select-->
<!--              v-model="selectLanguage"-->
<!--              @change="handleChangeLanguage"-->
<!--              placeholder="Choose a language"-->
<!--              class="select-language"-->
<!--              popper-class="select-language-dropdown"-->
<!--            >-->
<!--              <el-option :label="`${$t('language.en')}`" value="en">-->
<!--                <template>-->
<!--                  <div class="be-flex flag">-->
<!--                    <img-->
<!--                      src="../assets/images/us-flag.svg"-->
<!--                      alt=""-->
<!--                      size="14"-->
<!--                      style="-->
<!--                        margin-right: 6px;-->
<!--                        width: 19px;-->
<!--                        height: 19px;-->
<!--                        margin-top: 1px;-->
<!--                      "-->
<!--                    />-->
<!--                    <span class="lang">{{ $t("language.en") }}</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-option>-->
<!--              <el-option :label="`${$t('language.vi')}`" value="vi">-->
<!--                <template>-->
<!--                  <div class="be-flex flag">-->
<!--                    <img-->
<!--                      src="../assets/images/vn-flag.svg"-->
<!--                      alt=""-->
<!--                      size="19"-->
<!--                      style="-->
<!--                        width: 22px;-->
<!--                        height: 22px;-->
<!--                        margin-right: 4px;-->
<!--                        margin-left: -2px;-->
<!--                        filter: brightness(1.1) contrast(1.3);-->
<!--                      "-->
<!--                    />-->
<!--                    <span class="langs" style="">{{ $t("language.vi") }}</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-option>-->
<!--              <div class="suffix" slot="prefix">-->
<!--                <img-->
<!--                  src="../assets/images/us-flag.svg"-->
<!--                  style="margin-right: 8px; margin-left: 0px; margin-top: 10px"-->
<!--                  v-if="getIcon === 'us-flag'"-->
<!--                  alt=""-->
<!--                />-->
<!--                <img-->
<!--                  src="../assets/images/vn-flag.svg"-->
<!--                  style="-->
<!--                    margin-right: 8px;-->
<!--                    margin-left: 0px;-->
<!--                    width: 24px;-->
<!--                    height: 23px;-->
<!--                    margin-top: 8px;-->
<!--                    filter: brightness(1.1) contrast(1.3);-->
<!--                  "-->
<!--                  v-else-->
<!--                  class="vn-flag"-->
<!--                  alt=""-->
<!--                />-->
<!--              </div>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="menu-item nav__mobile-item lang2">-->
<!--          <div class="language2 language-select-wraper">-->
<!--            <el-select-->
<!--              v-model="selectLanguage"-->
<!--              @click="handleChangeLanguage"-->
<!--              placeholder="Choose a language"-->
<!--              class="select-language"-->
<!--              popper-class="select-language-dropdown"-->
<!--            >-->
<!--              <el-option :label="`${$t('language.en-mobile')}`" value="en">-->
<!--                <template>-->
<!--                  <div class="be-flex flag" style="justify-content: unset">-->
<!--                    <img-->
<!--                      src="../assets/images/us-flag.svg"-->
<!--                      alt=""-->
<!--                      size="14"-->
<!--                      style="-->
<!--                        margin-right: 6px;-->
<!--                        width: 19px;-->
<!--                        height: 19px;-->
<!--                        margin-top: 1px;-->
<!--                      "-->
<!--                    />-->
<!--                    <span class="lang">{{ $t("language.en-mobile") }}</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-option>-->
<!--              <el-option :label="`${$t('language.vi-mobile')}`" value="vi">-->
<!--                <template>-->
<!--                  <div class="be-flex flag" style="justify-content: unset">-->
<!--                    <img-->
<!--                      src="../assets/images/vn-flag.svg"-->
<!--                      alt=""-->
<!--                      size="19"-->
<!--                      style="-->
<!--                        width: 22px;-->
<!--                        height: 22px;-->
<!--                        margin-right: 4px;-->
<!--                        margin-left: -2px;-->
<!--                      "-->
<!--                    />-->
<!--                    <span class="langs" style="">{{-->
<!--                      $t("language.vi-mobile")-->
<!--                    }}</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-option>-->
<!--              <div class="suffix" slot="prefix">-->
<!--                <img-->
<!--                  src="../assets/images/us-flag.svg"-->
<!--                  style="margin-right: 8px; margin-left: 0px; margin-top: 10px"-->
<!--                  v-if="getIcon === 'us-flag'"-->
<!--                  alt=""-->
<!--                />-->
<!--                <img-->
<!--                  src="../assets/images/vn-flag.svg"-->
<!--                  style="-->
<!--                    margin-right: 8px;-->
<!--                    margin-left: 0px;-->
<!--                    width: 24px;-->
<!--                    height: 23px;-->
<!--                    margin-top: 8px;-->
<!--                  "-->
<!--                  v-else-->
<!--                  class="vn-flag"-->
<!--                  alt=""-->
<!--                />-->
<!--              </div>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectLanguage: "EN",
      isAbout: false,
      isCheckClick: true,
      tabActive: "",
      isChangeLogo: false,
      isDesktop: true
    };
  },
  watch: {
    "$route.name": function (_new) {
      if (_new === "About") {
        this.isAbout = true;
        this.isCheckClick = false;
      } else {
        this.isAbout = false;
        this.isCheckClick = true;
      }
    },
  },
  methods: {
    changeActive(item) {
      const sackItem = document.querySelector(".sack-item");
      if (window.innerWidth < 1023) {
        sackItem.style.transform = "translateX(100%)";
      }
      if (item == 0) {
        this.tabActive = 0;
      }
      if (item == 1) {
        this.tabActive = 1;
      }
      if (item == 2) {
        this.tabActive = 2;
      }
      if (item == 3) {
        this.tabActive = 3;
      }
      if (item == 4) {
        this.tabActive = 4;
      }
      if (item == 5) {
        this.tabActive = 5;
      }
      if (item == 6) {
        this.tabActive = 6;
      }
      if (item == 7) {
        this.tabActive = 7;
      }

      let anchorSelector = 'a[href^="#"]';

      // Collect all such anchor links
      let anchorList =
          document.querySelectorAll(anchorSelector);

      anchorList.forEach(link => {
        link.onclick = function (e) {
          e.preventDefault();
          let destination = document.querySelector(this.hash);

          destination.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });

    },

    handleChangeLanguage(lang) {
      this.$i18n.locale = lang;
      window.localStorage.setItem("bc-lang", lang);
    },
  },
  computed: {
    getIcon() {
      return this.selectLanguage === "vi" ? "vn-flag" : "us-flag";
    },
  },
  created() {
    this.isAbout = this.$route.name === "About";
    // window.addEventListener('resize', () => {
    //
    // })
    this.isDesktop = window.innerWidth > 1023;
  },
  mounted() {
    const lang = window.localStorage.getItem("bc-lang");
    const menuLink = document.querySelectorAll(".menu-link");
    console.log("menuLink", menuLink);
    const menuMobile = document.querySelector(".menu-mobile");
    const sackItem = document.querySelector(".sack-item");
    const iconClose = document.querySelector(".icon-close");
    if (!lang) {
      window.localStorage.setItem("bc-lang", "vi");
    }
    this.selectLanguage = window.localStorage.getItem("bc-lang");

    // effect menu
    const menu = document.querySelector(".menu");
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        menu.classList.add("backchange");
        this.isChangeLogo = true
      } else {
        menu.classList.remove("backchange");
        menuLink.forEach((x) => {
          x.classList.remove("active");
        });
        this.isChangeLogo = false
      }
    });
    // effect active
    // const liElement = document.querySelectorAll(".li-item");

    // menuLink.forEach((element) => {
    //   element.addEventListener("click", function () {
    //     if (window.innerWidth < 1023) {
    //       sackItem.style.transform = "translateX(100%)";
    //     }
    //     menuLink.forEach((aLink) => {
    //       aLink.classList.remove("active");
    //     });
    //     element.classList.add("active");
    //   });
    // });

    menuMobile.addEventListener("click", function () {
      sackItem.style.transform = "translateX(0%)";
    });
    iconClose.addEventListener("click", function () {
      sackItem.style.transform = "translateX(100%)";
    });
  },
};
</script>

<style lang="scss">
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.el-dropdown-menu__item{
  font-weight: 600;
  a{
    text-decoration: none;
    //color: #606266 !important;
    color:inherit;
  }
}
</style>

<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding-top: 28px;
  transition: 0.3s linear;
  .menu-logo img {
    //height: 64px;
  }
  .menu-container {
    max-width: 1340px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sack-item {
    display: flex;
    align-content: center;
    list-style: none;
    .menu-item {
      padding: 0 28px;
      display: flex;
      align-items: center;
    }
    .menu-item:last-child {
      padding-right: 0;
    }
    .menu-link {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      line-height: 150%;
      font-weight: 600;
      position: relative;
      padding-bottom: 15px;
      margin-top: 15px;
      color: #ececec;
    }
  }
  .menu-item:hover .menu-link {
    color: #fff;
  }
}
.backchange,
.backchange2 {
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding-top: 0;
}
.backchange {
  .menu-logo img {
    //height: 64px;
  }
}
.backchange,
.backchange2 {
  .sack-item .menu-item .menu-link {
    padding-bottom: 19px;
    margin-top: 19px;
    color: #363636 !important;
  }
  .sack-menu-effect {
    background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
  }
  .menu-item:hover .menu-link {
    background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.menu-link.active {
  background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600 !important;
}
.active .sack-menu-effect {
  width: 100%;
}
.be-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-select-wraper {
  width: 93px;
  select-language {
    input {
      background: none;
      border: none;
    }
    .suffix {
      .us-flag {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 7px;
        display: block;
      }
      .vn-flag {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 6px;
        display: block;
      }
    }
  }
}
.el-input__suffix {
  right: 10px;
  background: transparent;
}
.el-input--prefix .el-input__inner {
  padding-left: 50px;
}

.language-select-wraper .el-input--prefix .el-input__inner {
  padding-left: 70px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: transparent;
  color: #fff;
  width: 256px;
}
.el-select .el-input .el-select__caret {
  color: #c0c4cc;
  font-size: 26px !important;
  transition: transform 0.3s;
  transform: rotateZ(180deg);
  cursor: pointer;
  margin-right: 43px !important;
  margin-top: -9px !important;
}
.el-select .el-input .el-select__caret.is-reverse {
  transform: rotateZ(0);
  margin-top: 10px !important;
}
.sack-menu-effect {
  position: absolute;
  width: 0%;
  height: 2px;
  z-index: 1;
  background: white;
  bottom: -2px;
  left: 0;
  transition: width ease 0.3s;
  display: inline-block;
}

.select-language-dropdown {
  .el-select-dropdown__item {
    font-weight: 400;
  }
}
.menu-item :hover .sack-menu-effect {
  width: 100%;
}
.menu-mobile {
  display: none;
}
.bar1 {
  width: 15px;
  height: 2px;
  transform: translateX(9px);
}
.bar2 {
  width: 24px;
  height: 2px;
}
.bar3 {
  width: 15px;
  height: 2px;
  transform: translateX(9px);
}
.backchange,
.backchange2 {
  .bar1,
  .bar2,
  .bar3 {
    background: #363636;
    border-radius: 99px;
    margin: 6px 0;
    transition: 0.4s;
  }
}
.bar1,
.bar2,
.bar3 {
  background: #fff;
  border-radius: 99px;
  margin: 6px 0;
  transition: 0.4s;
}
.icon-close {
  display: none;
}
.menu-item.network {
  display: none !important;
}
.menu-item.lang1 {
  padding-right: 0 !important;
}
.menu-item.lang2 {
  display: none !important;
}

@media screen and (max-width: 1023px) {
  .menu-mobile {
    display: block;
  }
  .menu-item.network {
    display: flex !important;
  }
  .menu-item.lang1 {
    display: none !important;
  }
  .menu-item.lang2 {
    display: flex !important;
  }
  .network {
    margin-top: 61px;
    margin-bottom: 48px;
    @media screen and (max-height: 650px) {
      margin-top: -4px;
      margin-bottom: 20px;
    }
  }
  .icon-close {
    text-align: right;
    display: block;
    margin-right: 24px;
    margin-top: 20px;
  }
  .menu {
    padding-top: 4px;
    .sack-item {
      flex-direction: column;
      position: fixed;
      top: 0px;
      background: #fafafa;
      padding-top: -40px;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(100%);
      transition: 0.5s ease;
      .menu-item {
        .menu-link {
          font-weight: 700;
          color: #363636;
          font-size: 24px;
          line-height: 28px;
          margin-top: 25px;
          padding-right: 58px;
          @media screen and (max-height: 650px) {
            margin-top: 20px;
          }
        }
      }
    }
    .menu-logo img {
      //height: 48px;
    }
    .sack-menu-effect {
      display: none !important;
    }
  }
  .social_list {
    display: flex;
    justify-content: start;
    padding-top: 2.5rem;

    .social_iem {
      margin: -5px 6px;
      position: static;
      width: 44px;
      height: 42px;
      left: 0px;
      top: 0px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-top: 2.5px;
      }
    }
    .social_iem:first-child {
      margin-left: 0;
    }
  }
  .language2 .select-language {
    min-width: 147px !important;
  }

  .el-collapse{
    border-top: 0;
    border-bottom: 0;
    margin-top: 25px;
    .el-collapse-item{
      width: 100% ;
    }
    ::v-deep.el-collapse-item__header{
      background: none;
      border-bottom: 0;
    }
    ::v-deep.el-collapse-item__wrap {
      background: none;
      border-bottom: 0;
    }
  }
  .menu-item .collapse-item{
    .title {
      padding: 6px 16px;
      font-weight: 600;
      a{
        text-decoration: none;
        color: #363636;
      }
    }
  }
}
</style>

<!--<style lang="scss">-->
<!--::v-deep.el-dropdown-menu .el-popper .dropdown-service{-->
<!--  margin-left: -187px !important;-->
<!--}-->
<!--</style>-->
