<template>
  <div id="app">
<!--    <transition name="el-fade-in-linear">-->
<!--      <base-page-loading v-if="isLoading" />-->
<!--    </transition>-->
    <div class="main" v-if="!isLoading">
      <Menu />
      <router-view></router-view>
      <div id="backtop" @click="toTop" v-show="scY > 300">
        <div class="icon-backtop">
          <img src="./assets/images/backtop.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultProject from "./utils/firebase";
import JSEncrypt from "jsencrypt";
import axios from "axios";
// import Menu from "./components/Menu.vue";
import Menu from "./components/Menu2.vue";
// import BasePageLoading from "./components/BasePageLoading.vue";
export default {
  components: {
    Menu,
  },
  data() {
    return {
      isLoading: false,
      scTimer: 0,
      scY: 0,
      isAuthen: false,
    };
  },

  async created() {
    try {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 400);
      await this.handleSignInFireBase();
    } catch (error) {
      this.isLoading = false;
    }
  },
  watch: {
    // "$route.name": function (_new) {
    //   this.isisLoading = true;
    //   setTimeout(() => {
    //     _new;
    //   }, 500);
    //   setTimeout(() => {
    //     this.isisLoading = false;
    //   }, 1000);
    // },
  },

  methods: {
    async handleSignInFireBase() {
      try {
        const data = {
          password: this.encryptPassword("Beedu@#$%^&@2022$#@"),
        };
        const result = await this.getToken(data);
        await defaultProject.auth().signInWithCustomToken(result.authToken);
      } catch (error) {
        console.log(error);
      }
    },
    encryptPassword(pass) {
      const PUBLIC_KEY =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgw0IeOV0DZL1ZfzsSSbZDRu9XPPBkb4EVsFKYODFJnEEMdVK3IBhCyehoUbJFufIvdQD78LyfRo46fB5B6k+EsLC/4vofQiYyE/R13r+Fd+xufTXpxDKkj544LtfBfvsh/fYFx+vUH1/5XtuCplBX4cOsIdMni026zZv46clJswIDAQAB";
      const RSAEncrypt = new JSEncrypt();
      RSAEncrypt.setPublicKey(PUBLIC_KEY);
      return RSAEncrypt.encrypt(pass);
    },
    async getToken(data) {
      try {
        const result = await axios.post(
          "https://ws.bework.vn/api/v1/firebase/auth",
          data
        );
        return Promise.resolve(result.data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
}
#backtop {
  position: fixed;
  bottom: 40px;
  right: 14px;
  height: auto;
  z-index: 1000;
  cursor: pointer;
  @media (max-width: 739px) {
    bottom: 40px;
    right: 20px;
  }
}
@media (max-width: 1024px) and (min-width: 740px) {
  #backtop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
  }
}
@media (max-width: 739px) {
  #backtop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
  }
}

// @font-face {
//   font-weight: 300;
//   src: url("./San-Francisco-Pro-Fonts-master/SF-Pro-Text-Light.otf");
//   font-display: swap;
// }
// @font-face {
//   font-weight: 400;;
//   src: url("./San-Francisco-Pro-Fonts-master/SF-Pro-Text-Regular.otf");
//   font-display: swap;
// }
// @font-face {
//   font-weight: 500;;
//   src: url("./San-Francisco-Pro-Fonts-master/SF-Pro-Text-Medium.otf");
//   font-display: swap;
// }
// @font-face {
//   font-weight: 600;;
//   src: url("./San-Francisco-Pro-Fonts-master/SF-Pro-Text-Semibold.otf");
//   font-display: swap;
// }
// @font-face {
//   font-weight: 700;;
//   src: url("./San-Francisco-Pro-Fonts-master/SF-Pro-Text-Bold.otf");
//   font-display: swap;
// }
// @font-face {
//   font-weight: 800;;
//   src: url("./San-Francisco-Pro-Fonts-master/SF-Pro-Text-Black.otf");
//   font-display: swap;
// }
</style>
